import React from "react";
import "./Header.css";
import {Fade} from "react-reveal";
import {greeting, workExperiences} from "../../portfolio";

function Header() {
  const exp = workExperiences.viewExperiences;
  return (
    <Fade top duration={1000} distance="20px">
    <div>
      <header className="header">
        <a href="" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <a href="#projects">Projects</a>
          </li>
          {
          // { exp === true &&
          //   <li>
          //     <a href="#experience">Work</a>
          //   </li>
          // }
          
          // <li>
          //   <a href="#achievements">Achievements</a>
          // </li>
          }
          <li>
            <a href="#blogs">Blog</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </header>
    </div>
    </Fade>
  );
}
export default Header;
